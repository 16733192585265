import './App.css';
import Poster from './Components/Gocrcik/Poster';

function App() {
  return (
    <div className="App">
   <Poster/>    
    </div>
  );
}

export default App;
